import React, { Component } from "react"
import { Link } from 'gatsby'
import logo from "../../images/footer-images/mainLogo.png"
import copyrightImage from "../../images/footer-images/copyright.png"
import linkedin from "../../images/footer-images/linkedin.png"
import youtube from "../../images/footer-images/youtube.png"
import facebook from "../../images/footer-images/facebook.png"
import instagram from "../../images/footer-images/instagram.png"
import tiktok from "../../images/footer-images/tiktok.png"
import "../../myStyle.css"
import "./Footer.css"
import TextStyle from "../style-compoment/TextStyle"
import CookieConsent, { Cookies } from "react-cookie-consent";


class Footer extends Component {

    render () {

        const copyright = [
            <div className="footer-copyright-container">
                <img className="footer-copyright-icon" src={copyrightImage} alt="copyright"></img>
                <TextStyle
                    class="footer-text"
                    french="copyright 2022 Kodness. All rights reserved"
                    english="copyright 2022 Kodness. All rights reserved"
                    spanish="copyright 2022 Kodness. All rights reserved"
                ></TextStyle>
            </div>
        ]


        return (

            <div className="footer-container">
                <div className="footer-top-container">
                    <Link className="link" to='/'>
                        <img className="footer-logo" src={logo} alt="logo"></img>
                    </Link>
                    <div className="footer-share-container">
                        <TextStyle
                            class="medium"
                            french="Suivez-nous"
                            english="copyright 2022 Kodness. All rights reserved"
                            spanish="copyright 2022 Kodness. All rights reserved"
                            cssStyle={{color: "white", fontSize: "larger", fontFamily: "syncopate"}}
                        ></TextStyle>
                        <div className="footer-share-container-icon">
                            <img className="footer-icon" src={youtube} alt="youtube"></img>
                            <img className="footer-icon" src={instagram} alt="instagram"></img>
                            <img className="footer-icon" src={facebook} alt="facebook"></img>
                            <img className="footer-icon" src={linkedin} alt="linkedin"></img>
                            <img className="footer-icon" src={tiktok} alt="tiktok"></img>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom-container">
                        {copyright}

                        {/* <Link className="link" to='/cgu'>
                            <TextStyle
                                class="footer-text"
                                french="CGU"
                                english="CGU"
                                spanish="CGU"
                                cssStyle={{marginRight: "10px"}}
                            ></TextStyle>
                        </Link>
                        <Link className="link" to='/cgv'>
                            <TextStyle
                                class="footer-text"
                                french="CGV"
                                english="CGV"
                                spanish="CGV"
                            ></TextStyle>
                        </Link> */}
                        <div style={{
                            display: "flex"
                        }}>
                            <Link className="link" to='/legalNotice'>
                                <TextStyle
                                    class="footer-text"
                                    french="Mentions légales"
                                    english="Legal Notice"
                                    spanish="Notas legales"
                                    cssStyle={{marginRight: "30px"}}
                                ></TextStyle>
                            </Link>
                            <Link className="link" style={{zIndex: "10"}} to='/contact'>
                                <TextStyle
                                    class="footer-text"
                                    french="Contact"
                                    english="Contact"
                                    spanish="Contactar"
                                ></TextStyle>    
                            </Link>
                        </div>
        
                </div>
                <CookieConsent
                    enableDeclineButton
                    declineButtonStyle={{ color: "var(--greyColor)", color: "var(--navyColor)", fontSize: "13px" }}
                    declineButtonText="Refuser"
                    buttonText="Accepter"
                    cookieName="gatsby-gdpr-google-analytics"
                    buttonStyle={{ color: "var(--buttonColor)", color: "var(--navyColor)", fontSize: "13px" }}
                    style={{backgroundColor: "var(--navyColor)", color: "white", fontFamily: "prompt", borderTop: "1px solid white"}}>
                Ce site web utilise des cookies pour améliorer l'expérience utilisateur.
                </CookieConsent>

            </div>
        )
        




    }
}

export default Footer

