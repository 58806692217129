import React from "react"
import "../../myStyle.css"
import "./CallToAction.css"
import { useSelector } from 'react-redux';

function CallToAction(props) {

    const language = useSelector(state => state.language);

    let name = props.french
    if (language === 'english') {
        name = props.english
    }
    if (language === 'spanish') {
        name = props.spanish
    }


        let color = "style-compoment-calltoAction-navy"
        if (props.color === "orange") {
            color = "style-compoment-calltoAction-orange"
        }
        if (props.color === "red") {
            color = "style-compoment-calltoAction-red"
        }


        return (

            <button className={"style-compoment-callToAction " + color} style={props.cssCode}>
                {name}
            </button>
        )

    
}

export default CallToAction
