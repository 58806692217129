import React, { Component } from "react"
import { Link } from 'gatsby'

import "../../myStyle.css"
import "./Header.css"

import CallToAction from "../style-compoment/CallToAction"
import TextStyle from "../style-compoment/TextStyle"

import logo from "../../images/header-images/logoHeader.png"
// import cartImage from "../../images/header-images/cart.png"
import French from "../../images/header-images/france.png"
import American from "../../images/header-images/united-kingdom.png"
import Spain from "../../images/header-images/spain.png"
import menu from "../../images/header-images/menu.png"

import { connect } from 'react-redux';
import reduxStore from '../../ReduxStore';
import {addcart} from '../../counterActions';

import {french, spanish, english} from '../../counterActions';


class Header extends Component {

    state = {
        hideWindow: "header-windowLanguage",
        hideMenu: "header-list",
        hideHeader: "header-container"
    }
  


    changeLanguage = (language) => {

        if (language === 'english') {
            reduxStore.dispatch(english(language))
        } else if (language === 'spanish') {
            reduxStore.dispatch(spanish(language))
        } else if (language === 'french') {
            reduxStore.dispatch(french(language))
        }
        this.setState({
            hideWindow: "header-windowLanguage"
        })

    }

    displayWindow = () => {
        if (this.state.hideWindow === "header-windowLanguage") {
            this.setState({
                hideWindow: "header-windowLanguage header-display-windowLanguage"
            })
        } else {
            this.setState({
                hideWindow: "header-windowLanguage"
            })
        }
        this.setState({
            hideMenu: "header-list"
        })
    }

    displayHideMenu = () => {

        if (this.state.hideMenu === "header-list") {

            this.setState({
                hideMenu: "header-list header-list-hideMenu"
            })
        } else {
            this.setState({
                hideMenu: "header-list"
            })
        }

        this.setState({
            hideWindow: "header-windowLanguage"
        })

    }

    handleResize = () => {

        if (document.body.clientWidth > 850) {
            this.setState({
                hideMenu: "header-list"
            })
        }


    }

    handleScroll = () => {

        if (window.scrollY < 10) {
            this.setState({
                hideHeader: "header-container"
            })
        } else {
            this.setState({
                hideHeader: "header-container header-shadow"
            })
        }
    }

    componentDidMount() {

        const getCartStorage = JSON.parse(localStorage.getItem('trainfast-cart'))

        console.log("getCartStorage ", getCartStorage)

        if (getCartStorage === null) {
            localStorage.clear()
            localStorage.setItem('trainfast-cart', JSON.stringify([[0, 0], [1, 0]]));
            reduxStore.dispatch(addcart([[0, 0], [1, 0]]))
        } else {
            reduxStore.dispatch(addcart(getCartStorage))
        }

        window.addEventListener('resize', this.handleResize);
        window.addEventListener('scroll', this.handleScroll);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('scroll', this.handleScroll);
    }




    cartCounter = (cart) => {
        try {
            let countCart = 0
            for (let i = 0; i < cart.length; i++) {
                countCart = countCart + cart[i][1]
            }
            return countCart
        } catch {
            return "error"
        }
    }

    sendToSubscribe = () => {

        const pos = document.getElementById('subscribe').offsetTop
        window.scrollTo(0, pos-100)

        this.setState({
            hideMenu: "header-list"
        })
    
    }

    render() {


        let about = "header-link"
        let homegym = "header-link"
        let contact = "header-link"

        switch (this.props.page) {
            case "about":
                about = "header-linkLine"
                break
            case "homegym":
                homegym = "header-linkLine"
                break
            case "contact":
                contact = "header-linkLine"
                break
            default:
                break
        }

        let cart = this.props.cartRedux
        
        let countCart = this.cartCounter(cart)

            let displayCart;
            if (countCart > 0) {
                displayCart = <h1 className="header-count-cart">{countCart}</h1>
            }

            let iconLanguage = French
            switch (this.props.language) {
                case 'english':
                    iconLanguage = American
                    break
                case 'spanish':
                    iconLanguage = Spain
                    break
                default:
                    iconLanguage = French
                    break
            }






        return (

            <div className={this.state.hideHeader}>
                <Link className="header-logoStyle link" to='/'>
                    <img className="header-logoImage" src={logo} alt="logo"></img>
                </Link>
                <div className="header-navBar">
                    <div className={this.state.hideMenu} id="menuView">
                        <Link className="link header-items" to='/about'>
                            <TextStyle
                                class={about}
                                french="Qui sommes-nous ?"
                                english="About"
                                spanish="Sobre"
                            ></TextStyle>
                        </Link>
                        {/* <Link className="link header-items" to='/homegym'>
                            <TextStyle
                                class={homegym}
                                french="Le produit"
                                english="Homegym"
                                spanish="El gimnasio"
                            ></TextStyle>
                        </Link> */}
                        <Link className="link header-items" to='/contact'>
                            <TextStyle
                                class={contact}
                                french="Contact"
                                english="App"
                                spanish="La aplicación"
                            ></TextStyle>    
                        </Link>
                        {/* <Link className="link header-items" to='/shop'> */}
                        <div onClick={() => this.sendToSubscribe()} className="header-items">
                            <CallToAction
                                french="Devenez bêta testeur"
                                english="Shop"
                                spanish="Tienda"
                                color="navy"
                            ></CallToAction>
                        {/* </Link> */}
                        </div>
                    </div>
                    <img
                        onClick={this.displayHideMenu}
                        className="header-menu header-items header-items-mobile header-icon" 
                        src={menu} 
                        alt="menu"
                        // id="menuBtn"
                    ></img>
                {/* </div>

                <div className="header-navBar"> */}

                    {/* <Link className="link header-items header-items-mobile" to='/cart'>
                        <div>
                            <img className="header-icon cart" style={{color: "white"}} src={cartImage} alt="panier"></img>
                            {displayCart}
                        </div>
                    </Link> */}
                    <img
                        onClick={this.displayWindow} 
                        className="header-icon header-items" 
                        src={iconLanguage} alt="profil"
                    ></img>
                    <div className={this.state.hideWindow}>
                        <img onClick={() => this.changeLanguage('french')} className="header-icon" src={French}></img>
                        <img onClick={() => this.changeLanguage('english')} className="header-icon" src={American}></img>
                        {/* <img onClick={() => this.changeLanguage('spanish')} className="header-icon" src={Spain}></img> */}
                    </div>
                    {/* <Link className="link header-items header-shop-desktop" to='/shop'>
                        <CallToAction
                            french="Boutique"
                            english="Shop"
                            spanish="Tienda"
                            color="navy"
                            cssCode={{width: "100px"}}
                        ></CallToAction>
                    </Link> */}
                </div>

            </div>
        )
    }
}


function mapStateToProps(state){
    return {
        cartRedux: state.cart,
        language: state.language
    }
}

export default connect(mapStateToProps)(Header);

