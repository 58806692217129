import React from "react"

import "../../myStyle.css"
import { Helmet } from "react-helmet"


function HelmetCompoment() {
    

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Kodness</title>
                    <meta name="theme-color" content="#ffffff" />
                    <meta name="description" content="Kodness est un système de musculation tout-en-un et compact" />
                </Helmet>
            </div>
        )

}

export default HelmetCompoment

