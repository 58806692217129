export const  french = () => {
    return {
        type: 'FRENCH',
    }
}
export const english = () => {
    return {
        type: 'ENGLISH',
    }
}

export const spanish = () => {
    return {
        type: 'SPANISH',
    }
}

export const addcart = (cart) => {
    return {
        type: 'ADDCART',
        payload: cart
    }
}
// export const  increment = (counterData) => {
//     return {
//         type: 'INCREMENT',
//         payload: counterData
//     }
// }
// export const decrement = (counterData) => {
//     return {
//         type: 'DECREMENT',
//         payload: counterData
//     }
// }

// export const reset = () => {
//     return {
//         type: 'RESET',
//     }
// }