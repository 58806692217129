import React from "react"
import "../../myStyle.css"
import "./TextStyle.css"

import { useSelector } from 'react-redux';

function TextStyle(props) {
   
    const language = useSelector(state => state.language);

    let name = props.french
    if (language === 'english') {
        name = props.english
    }
    if (language === 'spanish') {
        name = props.spanish
    }


    if (props.type === 'headBand') {
        return (
            <div className="headband-container" style={props.cssStyle}>
                <h2 id={props.id} className="medium center bold maxWidth" >{name}</h2>
            </div>
        )
    } else if (props.type === 'content') {
        
        return (
            <h2 id={props.id} className={props.class} style={props.cssStyle}>{props.content}</h2>
        );

    } else {

        return (
            <h2 id={props.id} className={props.class} style={props.cssStyle}>{name}</h2>
        );
    }

}

export default TextStyle;



